<template>
  <div>
    <v-container fluid class="pa-0">
      <section id="qr4" class="restaurant">
        <v-layout fill-height align-center justify-center>
          <v-flex>
            <v-row fill-height align="center" justify="center">
              <v-col
                cols="12"
                md="6"
                class="pa-6"
                align="left"
                justify="center"
              >
                <v-img contain src="../assets/logo.png"></v-img>
                <v-row justify="center" class="mt-6">
                  <v-col cols="auto">
                    <v-btn width="200px" light color="white" x-large @click="whatsappCall('+573004437805')">
                      Whatsapp
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn width="200px" light color="white" x-large @click="make_call('+573004437805')">
                      Llamar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </section>
    </v-container>

    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </div>
</template>

<script>
//

// import { webserver, get_UUID, getToday } from "../services/webserver.js";
// import createItem from "../utils/createItem.js";
// import createParty from "../utils/createParty.js";
// import qrCard from "../components/qrCard.vue";
// import SocialLogin from "../components/Login.vue";
// import BarCodeReader from "../components/BarCodeReader.vue";
// import Stripe from "../components/Stripe.vue";

//import * as db from "../firebaseDb";
//import { getDatabase, ref, set } from "firebase/database";
//const database = getDatabase();
//import { printDoc } from "../utils/escpos.js"; getFirestore, , getDocs

export default {
  name: "Conatct",
  components: {},
  data() {
    return {
      fab: null,
      color: "",
      flat: null,
    };
  },
  mounted() {
    //db;
    this.$vuetify.goTo(0);
  },
  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
  methods: {
    make_call(phone) {
      window.location.href = "tel:" + phone;
    },
    whatsappCall(mobile) {
      //console.log(this.order, mobile);
      var apilink = "http://";
      apilink += this.isMobile ? "api" : "web";
      apilink +=
        ".whatsapp.com/send?phone=" + mobile + "&text=" + encodeURI("Hola ...");
      window.open(apilink, "", "width=800,height=600");
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@600&display=swap");
.restaurant {
  /* background-image: url("../assets/restaurant.jpg"); */
  background: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  text-shadow: black -1px 2px 6px;
  top: 0px;
  /* text-shadow: black -1px 2px 6px; */
}

.div_absolute {
  position: realtive;
  width: 100%;
  bottom: 50px;
}

.tshadow {
  text-shadow: black -1px 2px 6px;
  color: white;
  font-size: 3em;
}

.bg-login {
  width: 25rem;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}
.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.features {
  background: #eeeaea;
  padding-top: 80px;
  padding-bottom: 80px;
  height: 100%;
}
.services {
  height: 100%;
  padding-top: 80px;
}
.supplier {
  /* background: url("../assets/bgapp.jpg"); */
  /* background: rgb(245, 249, 255); */
}

.suscribe {
  background: #1a1446;
  height: 50vh;
  color: white;
}

.qr4 {
  font-family: "Teko", sans-serif;
  height: 80vh;
  /* color: #cddb26; */
}

.imgsize {
  height: 60vh;
}

.svg-border-waves {
  bottom: 0px;
  left: 0;
  height: 50px;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .header {
    height: 100vh;
  }
  .services {
    height: 100vh;
  }
  .supplier {
    height: 100vh;
  }
  .qr4 {
    font-family: "Teko", sans-serif;
    height: 100vh;
  }
  .imgsize {
    height: 40vh;
  }
}
</style>
